.category-menu {
  position: relative;
  width: 200px;
  margin: 0 auto;
}

.menu-toggle {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  text-align: center;
}

.menu-list {
  display: none;
    /* Remove any fixed height or excessive padding */
    height: auto; /* Ensure height is determined by content */
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #e0e0e0; /* Light gray background */
  border: 1px solid #ccc;
}

.menu-list.open {
  display: block;
}

.menu-list li {
  border-bottom: 1px solid #ccc;
}

.menu-list li:last-child {
  border-bottom: none;
}

.menu-list a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  text-align: center;
}

/* Hover effect for menu items */
.menu-list a:hover {
  background-color: #d0d0d0; /* Slightly darker gray for hover effect */
}
