.featured-products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.featured-product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
}

.featured-product-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.featured-product-card .image-container {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.featured-product-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.featured-product-card h3 {
  font-size: 16px;
  margin-bottom: 10px;
  /* Limit to 2 lines of text */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.featured-product-card .price {
  font-weight: bold;
  color: #007bff;
  margin-top: auto; /* Push price to bottom of card */
}
