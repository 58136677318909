.product-details-page {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    min-height: calc(100vh - 100px); /* Adjust 100px based on your header/footer height */
    align-items: flex-start;
    position: relative; /* Set relative positioning for the container */
}

.product-images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
}

.main-image-container {
    width: 100%;
    margin-bottom: 20px;
}

.main-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.thumbnail-images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    width: 100%;
}

.thumbnail-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
}

.thumbnail-image.selected {
    border-color: #007bff;
}

.product-info {
    flex: 1;
    min-width: 300px;
    overflow-y: auto; /* Allow scrolling if content is too long */
    max-height: 80vh; /* Match the image max-height */
}

.product-options {
    margin-top: 20px;
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space out the columns */
}

.product-options select {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
}

.add-to-cart-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.add-to-cart-button.unavailable {
    background-color: #ccc;
    cursor: not-allowed;
}

.product-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
}

.main-image {
    width: 100%;
    margin-bottom: 20px;
}

.main-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.thumbnail-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    max-width: 100%;
}

.thumbnail-images img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
}

.thumbnail-images img.selected {
    border-color: #007bff;
}

@media (max-width: 768px) {
    .product-details-page {
        flex-direction: column;
    }

    .product-images-container {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .product-image {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .product-info {
        max-height: none;
    }
}
/* src/css/ProductDetailsPage.css */
.nav-button {
    background-color: #007bff; /* Replace with your nav color */
    color: white; /* Text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Adjusted padding for a regular button size */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
    margin: 20px 0; /* Margin for spacing above and below */
    display: inline-block; /* Changed to inline-block for regular width */
    position: absolute; /* Position the button absolutely */
   
    z-index: 10; /* Ensure the button is above other content */
}

.nav-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.variation-option {
  background-color: #d4edda; /* Light green background */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.variation-option-upc { /* Light green background */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

.variation-option label {
  display: block; /* Make label take full width */
  margin-bottom: 5px; /* Space between label and select */
  font-weight: bold; /* Make label bold */
}

.product-options {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space out the columns */
}

.variation-option {
    flex: 1; /* Allow each option to grow equally */
    margin-right: 10px; /* Space between columns */
}

.variation-option:last-child {
    margin-right: 0; /* Remove margin from the last column */
}

/* Add this CSS rule to remove the green background for the UPC input */

