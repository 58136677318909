.header {
  
  padding: 10px 0;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.nav-left .nav-right {
  display: flex;
  align-items: center;
}



.search-form {

  padding: 5px 10px;
  max-width: 300px;
  margin-right: 20px;
  max-width: 300px;
  display: flex;
  align-items: center;
  position: relative;
}

.search-button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;

  z-index: 1; /* Ensure the button is above the input */
}

.search-input {
  width: 100%;
  padding: 5px 10px;
  padding-right: 30px; /* Make room for the search button */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.nav-link {
  margin-left: 20px;
}


/* Adjust other styles as needed to fit the new layout */
@media (max-width: 768px) {
  .header {
  
   
  }

  .nav-container {
    flex-direction: column;
    padding: 0 10px;
  }

  .nav-left {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .search-form {
    max-width: none;
    width: 100%;
    margin-top: 50px;
  }

  .search-input {
    width: 100%;
  }

  .nav-right {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .home-link {
    order: -1;
    margin-right: auto;
  }

  .nav-link {
    margin: 5px 10px;
  }

  .user-welcome {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
