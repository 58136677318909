/* Global Styles */
:root {
  --primary-bg-color: #2c3e50; /* Adjust this color if needed to match your footer */
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header Styles */
.header {
  background-color: var(--primary-bg-color);
  padding: 1rem 0;
  color: white;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.nav-left, .nav-right {
  display: flex;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

button.nav-link {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.cart-link {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.cart-count {
  margin-left: 0.5rem;
  background-color: white;
  color: var(--primary-bg-color);
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .nav-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-right {
    margin-top: 1rem;
    align-self: flex-end;
  }
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #3498db;
}

/* Main Content Styles */
main {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

/* Home Page Styles */
.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.home-content {
  display: flex;
  gap: 2rem;
  margin-top: 1rem; /* Reduced from 2rem */
}



.main-content {
  flex-grow: 1;
}

.welcome-section {
  text-align: center;
  margin-bottom: 1rem; /* Reduced from 2rem */
  padding: 1rem; /* Reduced from 2rem */
  background-color: #f8f9fa;
  border-radius: 8px;
}

.welcome-section h1 {
  font-size: 2rem; /* Reduced from 2.5rem */
  color: #333;
  margin: 0;
}

.featured-products-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.featured-products-section h2 {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}


h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.featured-products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

/* Product Card Styles */
.product-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.product-card h3 {
  margin: 0.5rem 0;
  color: #2c3e50;
}

.product-card p {
  color: #3498db;
  font-weight: bold;
  margin: 0.5rem 0;
}

.product-card button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-card button:hover {
  background-color: #2980b9;
}

/* Admin Add Product Page Styles */
.admin-add-product form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

.admin-add-product input,
.admin-add-product textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-add-product button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-add-product button:hover {
  background-color: #27ae60;
}

/* Footer Styles */
footer {
  background-color: #2c3e50;
  color: white;
  text-align: center;
  padding: 1rem 0;
  margin-top: auto;
}

/* Home Page Styles */
.home-content {
  display: flex;
  gap: 2rem;
}



.category-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.category-item {
  margin-bottom: 0;
  border-bottom: 1px solid #e0e0e0;
}

.category-item:last-child {
  border-bottom: none;
}

.category-item a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
  padding: 0.7rem 0;
}

.category-item a:hover {
  color: #2980b9;
}

/* Style for main categories */
.category-item:nth-child(-n+4) {
  font-weight: bold;
}

.category-item:nth-child(-n+4) a {
  color: #2c3e50;
}

.category-item:nth-child(4) {
  border-bottom: 2px solid #3498db;
}

.featured-products-container {
  flex: 1;
}

.featured-products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .home-content {
    flex-direction: column;
  }

  .category-menu {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/* Category Menu Styles */
.category-menu {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.category-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-menu li {
  border-bottom: 1px solid #ccc;
}

.category-menu li:last-child {
  border-bottom: none;
}

.category-menu a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.category-menu a:hover {
  background-color: #f0f0f0;
}
.category-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.category-item {
  margin-bottom: 0.5rem;
}

.category-item a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
  padding: 0.3rem 0;
}

.category-item a:hover {
  color: #2980b9;
}

/* Style for main categories */
.category-item:nth-child(-n+4) {
  font-weight: bold;
}

.category-item:nth-child(-n+4) a {
  color: #2c3e50;
}

.category-item:nth-child(4) {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}

/* Category Menu Styles */
.category-list {
  display: flex !important;
  flex-direction: column !important;
}

.category-item {
  width: 100% !important;
  display: block !important;
}

/* Add Category Page Styles */
.add-category {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.add-category h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.add-category button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-category button:hover {
  background-color: #2980b9;
}

.login {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.login h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login form div {
  margin-bottom: 10px;
}

.login label {
  display: block;
  margin-bottom: 5px;
}

.login input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.login button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.login button:hover {
  background-color: #2980b9;
}

.footer {
  background-color: #2c3e50;
  color: white;
  padding: 2rem 0;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.footer p {
  margin: 0.5rem 0;
}

.footer a {
  color: #3498db;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-bottom {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
  color: #bdc3c7;
}

.cart-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.cart-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2c3e50;
}

.empty-cart {
  text-align: center;
  font-size: 18px;
  color: #7f8c8d;
}

.cart-items {
  border-top: 1px solid #ecf0f1;
}

.cart-item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #ecf0f1;
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-details h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #2c3e50;
}

.cart-item-price {
  font-weight: bold;
  color: #2980b9;
  margin-bottom: 10px;
}

.cart-item-actions {
  display: flex;
  align-items: center;
}

.cart-item-quantity {
  width: 50px;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 3px;
}

.remove-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button:hover {
  background-color: #c0392b;
}

.cart-summary {
  margin-top: 20px;
  text-align: right;
}

.cart-total {
  font-size: 24px;
  color: #2980b9;
}

.checkout-button {
  display: inline-block;
  background-color: #2ecc71;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: #27ae60;
}

.checkout-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.checkout-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2c3e50;
}

.checkout-content {
  display: flex;
  gap: 40px;
}

.checkout-form {
  flex: 3;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #34495e;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 16px;
}

.order-summary {
  flex: 2;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
  align-self: flex-start;
}

.order-summary h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #2c3e50;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}

.item-name {
  flex: 2;
}

.item-quantity {
  flex: 1;
  text-align: center;
}

.item-price {
  flex: 1;
  text-align: right;
}

.order-total {
  margin-top: 20px;
  font-size: 18px;
  text-align: right;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .checkout-content {
    flex-direction: column;
  }
  
  .order-summary {
    margin-top: 30px;
  }
}

.place-order-btn {
  background-color: #2ecc71;  /* Green color */
  color: white;
  border: none;
  padding: 15px 30px;  /* Increased padding for a bigger button */
  border-radius: 5px;
  font-size: 18px;  /* Larger font size */
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;  /* Full width button */
  margin-top: 20px;  /* Add some space above the button */
  font-weight: bold;  /* Make the text bold */
}

.place-order-btn:hover {
  background-color: #27ae60;  /* Darker green on hover */
}

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.signup-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.signup-form .form-group {
  margin-bottom: 15px;
}

.signup-form label {
  display: block;
  margin-bottom: 5px;
}

.signup-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.signup-btn {
  width: 100%;
  padding: 10px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.signup-btn:hover {
  background-color: #27ae60;
}

.header {
  background-color: var(--primary-bg-color);
  padding: 1.5rem 0; /* Increased padding to make the header taller */
  color: white;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.nav-section {
  display: flex;
  gap: 1rem;
  align-items: center; /* Ensures vertical alignment of items */
}

.nav-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-section a, .nav-section button {
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  font-size: 1.1rem; /* Slightly larger font size */
}

.nav-section a:hover, .nav-section button:hover {
  color: #ddd;
}

.nav-section button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

@media (max-width: 768px) {
  .nav-container {
    flex-direction: column;
    gap: 1rem;
  }

  .nav-center {
    position: static;
    transform: none;
  }
}

.category-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0;
}

.category-card {
  height: 200px;
  text-decoration: none;
  color: white;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.category-card:hover {
  transform: scale(1.05);
}

.category-card-inner {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.category-card-inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.category-card h3 {
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .category-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .category-cards {
    grid-template-columns: 1fr;
  }
}

.product-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.product-details img {
  max-width: 100%;
  height: auto;
}

.product-details button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.product-details-container {
  display: flex;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
  gap: 2rem;
}

.product-image {
  width: 200px;  /* Increase from the current size */
  height: 200px; /* Increase from the current size */
  object-fit: cover; /* This ensures the image covers the area without distortion */
  border-radius: 4px; /* Optional: adds slightly rounded corners */
}

.product-info {
  flex: 1;
}

.product-name {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.product-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4CAF50;
  margin-bottom: 1rem;
}

.product-description {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.product-options {
  margin-bottom: 1.5rem;
}

.product-options h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.option-buttons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.option-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option-button:hover {
  background-color: #f0f0f0;
}

.add-to-cart-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.add-to-cart-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .product-details-container {
    flex-direction: column;
  }
}

.loading {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
}

.admin-add-product select[multiple] {
  height: 100px;
}

.admin-add-product input[type="file"] {
  padding: 10px 0;
}

.admin-add-product input[type="checkbox"] {
  margin-right: 5px;
}

.admin-add-product {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-add-product h2 {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-row {
  display: flex;
  gap: 1rem;
}

.form-row .form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: 500;
}

input[type="text"],
input[type="number"],
textarea,
select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  height: 100px;
  resize: vertical;
}

input[type="file"] {
  padding: 0.5rem 0;
}

select[multiple] {
  height: 100px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 0.5rem;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #45a049;
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
  }
}

.categories-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.categories-table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.categories-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.categories-table tr {
  cursor: pointer;
  transition: background-color 0.3s;
}

.categories-table tr:hover {
  background-color: #f5f5f5;
}
.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ok-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.ok-button:hover {
  background-color: #218838;
}

.add-new-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.add-new-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.add-new-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-new-button::before {
  content: "+";
  margin-right: 8px;
  font-size: 18px;
  font-weight: bold;
}
.admin-products-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.table-container {
  overflow-x: auto;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.products-table th,
.products-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.products-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  font-size: 14px;
}

.product-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-row:hover {
  background-color: #f0f0f0;
}

@media (max-width: 600px) {
  .products-table th,
  .products-table td {
    padding: 8px 10px;
    font-size: 14px;
  }
}


.admin-products-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  margin-bottom: 20px;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.page-size-selector {
  display: flex;
  align-items: center;
}

.page-size-selector label {
  margin-right: 10px;
}

.page-size-selector select {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
}

.products-table th,
.products-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.products-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.product-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.product-image {
  width: 100px;  /* Increase this value to make images larger */
  height: 100px; /* Increase this value to make images larger */
  object-fit: cover; /* This ensures the image covers the area without distortion */
  border-radius: 4px; /* Optional: adds slightly rounded corners */
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  font-size: 14px;
}


.variations-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.variations-table th,
.variations-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.variations-table th {
  background-color: #f2f2f2;
}

.add-variation-btn {
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.add-variation-btn:hover {
  background-color: #45a049;
}

.add-product-btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.add-product-btn:hover {
  background-color: #45a049;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.remove-variation-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.remove-variation-btn:hover {
  background-color: #ff1a1a;
}

.delete-product-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.delete-product-btn:hover {
  background-color: #ff1a1a;
}
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.delete-button, .update-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button {
  background-color: red;
  color: white;
}

.update-button {
  background-color: #007bff;
  color: white;
}

.cart-item-variations {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
}

.cart-item-variations span {
  margin-right: 10px;
}

.cart-item-variations span:last-child {
  margin-right: 0;
}

.products-table td {
  vertical-align: middle; /* This centers the content vertically in table cells */
  padding: 10px; /* Increase padding to give more space around cell content */
}
.variations-table {
  width: 100%;
  border-collapse: collapse;
}

.variations-table th,
.variations-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.variations-table .sku-column {
  width: 25%;
}

.variations-table .size-column,
.variations-table .color-column {
  width: 15%;
}

.variations-table .case-pack-column {
  width: 15%;
}

.variations-table .stock-column {
  width: 10%;
}
.variations-table .stock-column2 {
  width: 10%;
  disabled:"disabled"
}

.checkbox-group {
  display: flex;
  align-items: center; /* Aligns checkbox and label vertically */
}

.checkbox-group input {
  margin-right: 8px; /* Adds space between checkbox and label */
}
.table-controls {
  display: flex;
  justify-content: space-between; /* Space between items */
  align-items: center; /* Center items vertically */
  margin-bottom: 20px; /* Space below the controls */
}

.variations-table .price-column {
  width: 15%;
}

.variations-table .images-column {
  width: 100%;
}

.variations-table .is-active-column {
  width: 1%;
}

.variations-table .action-column {
  width: 1%;
}

.variations-table input[type="number"],
.variations-table input[type="checkbox"] {
  width: 100%;
  box-sizing: border-box;
}

/* Style for react-select components */
.variations-table .Select {
  width: 100%;
}

/* Style for the checkbox */
.variations-table input[type="checkbox"] {
  transform: scale(1.5);
  margin: 0 auto;
  display: block;
}

/* Style for the remove button */
.remove-variation-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.remove-variation-btn:hover {
  background-color: #ff1a1a;
}

.error-message {
  color: #ff0000;
  background-color: #ffeeee;
  border: 1px solid #ff0000;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.admin-orders-page {
  padding: 20px;
}

.table-container {
  overflow-x: auto;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.orders-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.orders-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.orders-table tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: bold;
}

.status-pending {
  background-color: #ffd700;
  color: #000;
}

.status-processing {
  background-color: #1e90ff;
  color: #fff;
}

.status-shipped {
  background-color: #32cd32;
  color: #fff;
}

.status-delivered {
  background-color: #008000;
  color: #fff;
}

.status-cancelled {
  background-color: #ff0000;
  color: #fff;
}

/* Improve pagination styling */
.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-button {
  padding: 8px 16px;
  margin: 0 5px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  margin: 0 10px;
}

/* Add this to your CSS file (e.g., index.css) */
.variations-table {
  width: 100%; /* Make the table full width */
  table-layout: auto; /* Optional: allows the table to adjust column widths based on content */
}