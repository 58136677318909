.checkbox-group {
    display: flex;
    align-items: center; /* Aligns checkbox and label vertically */
  }
  
  .checkbox-group input {
    margin-right: 8px; /* Adds space between checkbox and label */
  }


