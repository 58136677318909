/* Add this at the top of your CSS file */
* {
  box-sizing: border-box;
}

body, html {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.category-products-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.left-sidebar {
  width: 250px;
  padding: 0 20px 20px 0;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.product-card {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.product-card img {
  max-width: 100%;
  height: auto;
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-card h3 {
  margin: 10px 0;
  font-size: 1.1em;
}

.product-card p {
  margin: 5px 0;
}

.product-card .price {
  font-weight: bold;
  color: #007bff;
}

.product-card button {
  margin-top: auto;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.product-card button:hover {
  background-color: #0056b3;
}

/* Responsive design */
@media (max-width: 1024px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  .content-wrapper {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .sidebar {
    width: 200px;
    margin-right: 20px;
    text-align: left;
  }

  .product-section {
    flex: 1;
    max-width: calc(100% - 220px);
  }
}
