@media (max-width: 768px) {
    .main-image {
      width: 100% !important;
    }
  }

  @media (min-width: 769px) {
  .main-image {
    width: '50%';
    height: 'auto';
    display: 'block';
    margin: '0 auto';
  }
}